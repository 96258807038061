import React, { Component } from "react";
import { Link } from "react-router-dom";
import Header from "../Header";
import TrendingNews from "./trendingNews";

class AddNews extends Component {
  state = {};
  render() {
    return (
      <React.Fragment>
        <div className="container">
          <div class="main-menu-area bg-body bg">
            <nav className="navbar navbar-expand-lg navbar-light">
              <ul className="navbar-nav me-20 mb-2 mb-lg-0">
                <li className="nav-item alignment">
                  <Link className="nav-link active" aria-current="page" to="/">
                    HOME
                  </Link>
                </li>

                <li className="nav-item alignment">
                  <Link
                    className="nav-link"
                    aria-current="page"
                    to="/admin"
                    tabindex="-1"
                    aria-disabled="true"
                  >
                    TRENDING NEWS
                  </Link>
                </li>

                <li className="nav-item alignment">
                  <Link
                    className="nav-link"
                    to="/allposts"
                    tabindex="-1"
                    aria-disabled="true"
                  >
                    ALL POSTS
                  </Link>
                </li>

            
                {/* <li className="nav-item alignment">
                  <Link
                    className="nav-link"
                    to="/Videos"
                    tabindex="-1"
                    aria-disabled="true"
                  >
                    VIDEOS
                  </Link>
                </li>
                <li className="nav-item alignment">
                  <Link
                    className="nav-link"
                    to="/Gallery"
                    tabindex="-1"
                    aria-disabled="true"
                  >
                    GALLERY
                  </Link>
                </li> */}
                
              </ul>
            </nav>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default AddNews;
