import React, { Component } from "react";
import news73 from "../assets/img/news/news73.jpg";
import news75 from "../assets/img/news/news75.jpg";
import gif from "../assets/img/loader.gif";
import news74 from "../assets/img/news/news74.jpg";
import ctg2 from "../assets/img/category/ctg2.jpg";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { Link } from "react-router-dom";
import TextScroll from "./textscroll";
import { connect } from "react-redux";

class Slider extends Component {
  state = {
    content: [],
    trend: [],
  };

  async componentDidMount() {
    const a = await this.props.trendContent;
    this.setState({ content: a.data });

    const b = await this.props.trendSingle;
    this.setState({ trend: b.data });
  }

  render() {
    const pt = this.state.content;

    const z = this.state.trend;

    var ar1 = [];

    z.map((r) => {
      r.map((t) => {
        ar1.push(t);
      });
    });

    var ar = [];

    pt.map((c) => {
      c.map((k) => {
        ar.push(k);
      });
    });

    const tt = ar.filter((g) => {
      return g.category.includes("Travel");
    });

    const bt = ar.filter((g) => {
      return g.category.includes("Business");
    });

    return (
      <React.Fragment>
        <TextScroll />
        <section class="bg-accent section-space-bottom-less4">
          <div class="container">
            <div class="row tab-space2">
              <div class="col-md-8 col-sm-12 mb-4">
                <Carousel
                  showIndicators={false}
                  autoPlay={true}
                  showArrows={false}
                  infiniteLoop={true}
                  showThumbs={false}
                  itemsToShow={1}
                  showStatus={false}
                >
                  {ar1 &&
                    ar1.length > 0 &&
                    ar1.map((p) => {
                      return (
                        <div class="img-overlay-70 img-scale-animate">
                          <img
                            src={p.imageURL}
                            alt="news"

                            // class='img-fluid width-100'
                          />
                          <div class="mask-content-lg">
                            <div class="topic-box-sm color-cinnabar mb-20">
                              {p.category}
                            </div>

  
                            <div class="post-date-light">
                              <ul>
                               
                                  <li>
                                  <Link
                                to={{
                                  pathname: "/sports1",
                                  state: { p },
                                }}
                              >
                                {p.title}
                              </Link>

                                  </li>
                               
                                <li>
                                  <span>by</span>
                                  {/* <Link to=""> */}
                                    Adas
                                    {/* </Link> */}
                                </li>
                                <li>
                                  <span>
                                    <i
                                      class="fa fa-calendar"
                                      aria-hidden="true"
                                    ></i>
                                  </span>
                                  {p.date}
                                </li>
                              </ul>
                           
                            <h1 className="title-medium-light d-none d-sm-block">
                              {/* <Link
                                to={{
                                  pathname: "/sports1",
                                  state: { p },
                                }}
                              >
                                {p.title}
                              </Link> */}
                            </h1>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </Carousel>
              </div>

              <div class="col-md-4 col-sm-12">
                <div class="img-overlay-70 img-scale-animate mb-4">
                  <div class="mask-content-sm">
                    <div class="topic-box-sm color-razzmatazz mb-10">
                      {bt.length > 0 ? bt[1].category : null}
                    </div>
                    <h3 class="title-medium-light">
                      <Link
                        to={{
                          pathname: "/sports1",
                          state: { p: bt[1] },
                        }}
                      >
                        {bt.length > 0 ? bt[1].title : null}
                      </Link>
                    </h3>
                  </div>
                  <img
                    src={bt.length > 0 ? bt[1].imageURL : gif}
                    alt="news"
                    style={{
                      width: "400px",
                      height: "200px",
                      margintop: "1px",
                    }}
                    // class='img-fluid width-100'
                  />
                </div>
                <div class="img-overlay-70 img-scale-animate mb-4">
                  <div class="mask-content-sm">
                    <div class="topic-box-sm color-apple mb-10">
                      {tt.length > 0 ? tt[0].category : null}
                    </div>
                    <h3 class="title-medium-light">
                      {/* <a href="">{tt.length > 0 ? tt[1].title : null}</a> */}
                      <Link
                        to={{
                          pathname: "/sports1",
                          state: { p: tt[1] },
                        }}
                      >
                        {tt.length > 0 ? tt[1].title : null}
                      </Link>
                    </h3>
                  </div>
                  <img
                    src={tt.length > 0 ? tt[1].imageURL : gif}
                    alt="news"
                    style={{ width: "400px", height: "200px" }}
                    // class='img-fluid width-100'
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    trendContent: state.trendContent,
    trendSingle: state.trendSingle,
  };
};
export default connect(mapStateToProps)(Slider);
