import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import * as userService from "../services/userService";
import auth from "../services/authServices";
import Input from "./common/input";
import dark from "../assets/img/logo-dark.png";
import Form from "./common/form";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Joi from "joi-browser";

class Forgot extends Component {
  state = {
    data: {
      email: "",
    },
    errors: {},
  };

  schema = {
    email: Joi.string().required().label("Email"),
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) return;
    this.doSubmit();
  };
  validate = () => {
    const options = { abortEarly: false };
    const { error } = Joi.validate(this.state.data, this.schema, options);
    if (!error) return null;
    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  };
  validateProperty = ({ name, value }) => {
    const obj = { [name]: value };
    const schema = { [name]: this.schema[name] };
    const { error } = Joi.validate(obj, schema);
    return error ? error.details[0].message : null;
  };

  handleChange = ({ currentTarget: input }) => {
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];

    const data = { ...this.state.data };
    data[input.name] = input.value;
    this.setState({ data, errors });
  };

  doSubmit = async () => {
    console.log("submit");
  };

  render() {
    const { data, errors } = this.state;
    return (
      <div className="login-form col-mg-6">
        <form onSubmit={this.handleSubmit}>
          <div className=" form-list">
            <div className="logo-area">
              <Link to="#" className="img-fluid">
                <img src={dark} alt="logo" />
              </Link>
            </div>

            <h1 className="m-5 pl-0">What's your Email address?</h1>

            <h5 className="m-5">
              Enter the email you used to register with the CG NEWS and we'll
              send you a link to reset your password. If you have any problem
              please contanct us.
            </h5>

            <Input
              name="email"
              value={data.email}
              label="Email"
              placeholder="Email"
              onChange={this.handleChange}
              error={errors.email}
            />
          </div>

          <button type="submit" className="forgot-btn" value="submit">
            Submit
          </button>
        </form>
      </div>
    );
  }
}

export default Forgot;
