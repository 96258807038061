import store from "../store/index";
import { trendSingle } from "../../services/trendingService";

export var UPDATE_TRENDSINGLE = "UPDATE_TRENDSINGLE";

function update_trendSingle() {
  setTimeout(async () => {
    var trend = await trendSingle();

    store.dispatch({ type: UPDATE_TRENDSINGLE, payload: trend });
  }, 100);
}

export default update_trendSingle;
