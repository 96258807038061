import React, { Component } from "react";
import Header from "../Header";
import Menu from "./menu";
import * as postService from "../../services/postServices";
import Input from "../common/input";
import Footer from "../footer";
import Joi from "joi-browser";
import moment from 'moment';
import "react-datepicker/dist/react-datepicker.css";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class Trending extends Component {
  state = {
    data: {
      title: "",
      category: "",
      description: "",
      imageURL: "",
      video: "",
      date: "",
      time: "",

    },
    errors: {},
    btn_dis:false,
  };

  schema = {
    title: Joi.string().min(5).max(60).required().label("Title"),
    category: Joi.string().required().label("Category"),
    description: Joi.string().required().label("Description"),
    imageURL: Joi.string().required().label("ImageURl"),
    video: Joi.string().required().label("Video"),
    date: Joi.string().required().label("Date"),
    time: Joi.string().required().label("Time"),
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) return;
    this.doSubmit();
  };
  validate = () => {
    const options = { abortEarly: false };
    const { error } = Joi.validate(this.state.data, this.schema, options);
    if (!error) return null;
    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  };
  validateProperty = ({ name, value }) => {
    const obj = { [name]: value };
    const schema = { [name]: this.schema[name] };
    const { error } = Joi.validate(obj, schema);
    return error ? error.details[0].message : null;
  };

  handleChange = ({ currentTarget: input }) => {
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];

    const data = { ...this.state.data };
    data[input.name] = input.value;
    this.setState({ data, errors });
  };

  doSubmit = async () => {
    await this.setState({ btn_dis:true})
    if (this.state.data) {
    }
    try {
      const response = await postService.posting(this.state.data);
      if (response.data) {
        await this.setState({ data: {} });
        toast.success("data post in database",{
          position:toast.POSITION.TOP_RIGHT,
        })
  
        setTimeout(() => {
          
          window.location="/allposts";
        },1000);

      }
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        await this.setState({ btn_dis:false});
        // const errors = {...this.state.errors}
        // errors.username = ex.response.data;
        // this.setState({errors});
      }
    }
  };

  render() {
    const { data } = this.state;

    return (
      <React.Fragment>
        <Header />
        <Menu />
        <div className="bg-accent section-space-bottom-less4 pt-20">
          <div className="container">
            <form onSubmit={this.handleSubmit}>
              <div class="form-group mb-3">
                <label htmlFor="category" class="form-label font-weight-bold">
                  Category
                </label>
                <select
                  id="category"
                  class="form-select"
                  name="category"
                  value={data.category}
                  onChange={this.handleChange}
                  aria-label="Default select example"
                >
                  <option selected>Select Category</option>
                  <option value="Politics">Politics</option>
                  <option value="Business">Business</option>
                  <option value="Sports">Sports</option>
                  <option value="Fashion">Fashion</option>
                  <option value="Travel">Travel</option>
                </select>
              </div>

              <Input
                name="title"
                type="text"
                value={data.title}
                label="Title"
                onChange={this.handleChange}
              />

              <Input
                name="date"
                type="date"
                value= {data.date}
                label="Date"
                onChange={this.handleChange}
              />
              <Input
                name="time"
                type="time"
                value={data.time}
                label="Time"
                onChange={this.handleChange}
              />

        <div class="form-group mb-3">
            <label htmlFor="description" class="form-label">
              Description
            </label>
                <textarea class="form-control"
                name="description"
                value={data.description}
                onChange={this.handleChange} 
                id="description"  
                 rows="4"
                cols="100"
                style={{height: "100px"}}></textarea>
              </div>

              <Input
                name="imageURL"
                type="text"
                value={data.imageURL}
                label="imageURL"
                onChange={this.handleChange}
              />

              <Input
                name="video"
                type="text"
                value={data.video}
                label="video"
                onChange={this.handleChange}
              />

              <button disabled={this.state.btn_dis}  type="submit" class="btn btn-primary">
                Submit
              </button>
            </form>
          </div>
        </div>
        <Footer />
      </React.Fragment>
    );
  }
}

export default Trending;
