import React, { Component } from "react";
import dark from "../assets/img/logo-dark.png";
import banner from "../assets/img/banner/banner2.jpg";
import "react-responsive-modal/styles.css";
import { toast } from "react-toastify";
import { Link, Redirect } from "react-router-dom";
import auth from "../services/authServices";

class Header extends Component {
  state = {
    currentDateTime: new Date().toDateString(),
  };

  render() {
    return (
      <React.Fragment>
        <div id="wrapper" class="wrapper"></div>
        <header>
          <div id="header-layout2" class="header-style2">
            <div class="header-top-bar">
              <div class="top-bar-top bg-accent border-bottom">
                <div className="container ">
                  <div className="row">
                    <div className="col-lg-8 col-md-12">
                      <ul className="news-info-list text-center--md">
                        <li>
                          <i
                            className="fa fa-map-marker"
                            aria-hidden="true"
                          ></i>
                          India
                        </li>
                        <li>
                          <i className="fa fa-calendar" aria-hidden="true"></i>
                          <span id="current_date">
                            {this.state.currentDateTime}
                          </span>
                        </li>
                        <li>
                          <i className="fa fa-clock-o" aria-hidden="true"></i>
                          Last Update 11.30 am
                        </li>
                        <li>
                          <i className="fa fa-cloud" aria-hidden="true"></i>
                          29&#8451; Vijayawada, Andhra Pradesh
                        </li>
                      </ul>
                    </div>
                    <div className="col-lg-4 d-none d-lg-block">
                      <ul className="header-social">
                        <li>
                          <Link
                            to="https://www.facebook.com/"
                            title="facebook"
                            target="_blank"
                          >
                            <i
                              className="fa fa-facebook"
                              aria-hidden="true"
                            ></i>
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="https://twitter.com/LOGIN"
                            title="twitter"
                            target="_blank"
                          >
                            <i className="fa fa-twitter" aria-hidden="true"></i>
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="https://accounts.google.com/login"
                            target="_blank"
                            title="google-plus"
                          >
                            <i
                              className="fa fa-google-plus"
                              aria-hidden="true"
                            ></i>
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="https://www.linkedin.com/login"
                            target="_blank"
                            title="linkedin"
                          >
                            <i
                              className="fa fa-linkedin"
                              aria-hidden="true"
                            ></i>
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="https://in.pinterest.com/login/"
                            target="_blank"
                            title="pinterest"
                          >
                            <i
                              className="fa fa-pinterest"
                              aria-hidden="true"
                            ></i>
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="https://feeder.co/login"
                            target="_blank"
                            title="rss"
                          >
                            <i className="fa fa-rss" aria-hidden="true"></i>
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="https://vimeo.com/log_in"
                            target="_blank"
                            title="vimeo"
                          >
                            <i className="fa fa-vimeo" aria-hidden="true"></i>
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div className="top-bar-bottom bg-body pt-10 lg-block">
                <div className="container">
                  <div className="row d-flex align-items-center">
                    <div className="col-lg-4 col-md-4 col-sm-4">
                      <div className="logo-area d-sm-block">
                        <Link to="/" className="img-fluid">
                          <img src={dark} alt="logo" />
                        </Link>
                      </div>
                    </div>
                    <div className="col-lg-8 col-md-8 col-sm-8">
                      <div className="ne-banner-layout1 pull-right d-xs-none">
                        <Link to="">
                          <img src={banner} alt="ad" className="img-fluid" />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
      </React.Fragment>
    );
  }
}

export default Header;
