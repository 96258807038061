import store from "../store/index";
import { trendContent } from "../../services/trendingService";

export var UPDATE_TRENDCONTENT = "UPDATE_TRENDCONTENT";

function update_trendContent() {
  setTimeout(async () => {
    var totaltrend = await trendContent();

    store.dispatch({ type: UPDATE_TRENDCONTENT, payload: totaltrend });
  }, 100);
}

export default update_trendContent;
