import React from 'react';

const Input =({name,label,value,type,placeholder,error,onChange}) => {
    return (
        <React.Fragment>
        <div className="form-group">
            <label htmlfor={name}>{label}*</label>
            <input 
            value={value}
            type={type}
            onChange={onChange}
            id={name}
            name={name}
            placeholder={placeholder} 
            className="form-control" 
            />
            {error && <div className ="alert alert-danger">{error}</div>}
        </div>


            

</React.Fragment>

    );
}
 
export default Input;