import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import * as userService from "../services/userService";
import auth from "../services/authServices";
import Input from "./common/input";
import Form from "./common/form";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Joi from "joi-browser";

class RegisterForm extends Component {
  state = {
    data: {
      name: "",
      dateOfBirth: "",
      email: "",
      password: "",
      
    },
    btn_dis:false,
    errors: {},
  };

  schema = {
    name: Joi.string().required().label("Name"),
    dateOfBirth: Joi.string().required().label("DateOfBirth"),
    email: Joi.string().required().label("Email"),
    password: Joi.string().required().label("Password"),
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) return;
    this.doSubmit();
  };
  validate = () => {
    const options = { abortEarly: false };
    const { error } = Joi.validate(this.state.data, this.schema, options);
    if (!error) return null;
    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  };
  validateProperty = ({ name, value }) => {
    const obj = { [name]: value };
    const schema = { [name]: this.schema[name] };
    const { error } = Joi.validate(obj, schema);
    return error ? error.details[0].message : null;
  };

  handleChange = ({ currentTarget: input }) => {
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];

    const data = { ...this.state.data };
    data[input.name] = input.value;
    this.setState({ data, errors });
  };

  doSubmit = async () => {
    await this.setState({ btn_dis:true})
    if (this.state.data) {
    }
    try {
      const response = await userService.register(this.state.data);
      auth.loginWithJwt(response.headers["x-auth-token"]);

      toast.success("successfully register", {
        position: toast.POSITION.TOP_RIGHT,
      });

      setTimeout(() => {

        window.location = "/";
      }, 5000);
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        await this.setState({ btn_dis:false})
        toast.error(ex.response.data, {
          position: toast.POSITION.TOP_RIGHT,
        });
        // const errors = {...this.state.errors}
        // errors.username = ex.response.data;
        // this.setState({errors});
      }
    }
  };

  render() {
    const { data, errors } = this.state;
    return (
      <div className="login-form col-mg-6">
        <form onSubmit={this.handleSubmit}>
          <div className="form-row form-group">
            <div className="col">
              <Input
                name="name"
                value={data.name}
                label="Name"
                placeholder="Name"
                onChange={this.handleChange}
                error={errors.name}
              />
            </div>

            <div className="col">
              <Input
                name="dateOfBirth"
                value={data.dateOfBirth}
                label="DateOfBirth"
                type="date"
                placeholder="mm/dd/yyyy"
                onChange={this.handleChange}
                error={errors.name}
              />
            </div>
          </div>

          <Input
            name="email"
            value={data.email}
            label="Email"
            placeholder="Email"
            onChange={this.handleChange}
            error={errors.email}
          />

          <Input
            name="password"
            type="password"
            placeholder="Password"
            value={data.password}
            label="Password"
            onChange={this.handleChange}
            error={errors.password}
          />

          <div className="checkbox checkbox-primary">
            <input id="checkbox" type="checkbox" />
            <label className="form-check-label" htmlFor="checkbox">
              Remember Me
            </label>
          </div>
          <button  disabled={this.state.btn_dis} type="submit"  className="register" value="Register">
            Register
          </button>
        </form>
      </div>
    );
  }
}

export default RegisterForm;
