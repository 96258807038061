import React, { Component } from "react";
import Aside from "./aside";
// import news140 from '../../../assets/img/news/news140.jpg';
import { Link, Redirect } from "react-router-dom";
import Pagination from "../common/pagination";
import { paginate } from "../common/paginate";

class Article extends Component {
state = {
content: [],
currentPage: 1,
pageSize: 4,
loader: false,
};


componentDidMount() {
window.scrollTo(0, 0);
}


handlePageChange = (page) => {
this.setState({ currentPage: page });
};

getPagedData = () => {
const { pageSize, currentPage, content: alltrdata } = this.state;
let filtered = alltrdata;

const content = paginate(filtered, currentPage, pageSize);
return { totalCount: filtered.length, data: content };
};

render() {
const ct = this.props.history.location.state;

const { pageSize, currentPage } = this.state;
const { totalCount, data: content } = this.getPagedData();

return (
<section class="bg-body section-space-less30">
<div class="container">
<div class="row">
<div class="col-lg-8 col-md-12">
{ct && ct.length > 0
? ct.map((p) => (
<div>

<div class="row">
<div class="col-md-4 pl-md-0">
<div class="">
<div
to=""
class="img-opacity-hover img-overlay-70 media-smm col-xs-12"
>
<span className="">
<img
src={p.imageURL}
alt="news"
className="mb-14 "
/>
</span>
</div>
<div class="media-cat topic-box-top-xs">
<div class="topic-box-sm color-cod-gray mb-20">
{p.category}
</div>
</div>
</div>
</div>

<div class="col-md-8">

<div class=" p-mb-none-child media-margin30">
<div class="post-date-dark">
<ul>
<li>
<span>by</span>
{/* <Link to="#">  */}
Adams
{/* </Link> */}
</li>
<li>
<span>
<i
class="fa fa-calendar"
aria-hidden="true"
></i>
</span>
{p.date}
</li>
</ul>
</div>
<h3 class="title-semibold-dark size-lg mb-15">
<Link
to={{ pathname: "/sports1", state: { p } }}
>
{p.title}
</Link>
</h3>
<p style={{marginBottom:25}}>{p.description.length > 120 ? p.description.slice(0,120) + '...' : p.description}</p>
</div>
</div>

</div>

</div>
))
: null}



<div class="row mt-20-r mb-30">
<Pagination
itemsCount={totalCount}
pageSize={pageSize}
currentPage={currentPage}
onPageChange={this.handlePageChange}
/>
</div>
</div>
<Aside />
</div>
</div>
</section>

);
}
}

export default Article;