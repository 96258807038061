import { UPDATE_TRENDSINGLE } from "./../action/trendsingAction";
import { trendSingle } from "./../../services/trendingService";

const initialState = trendSingle();
const allContent = (state = initialState, { type, payload }) => {
  switch (type) {
    case UPDATE_TRENDSINGLE:
      return payload;
    default:
      return state;
  }
};
export default allContent;
