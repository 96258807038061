import React, { Component } from "react";
import HamburgerMenu from "./hamburger";

class Menu extends Component {
  state = {};
  render() {
    return <HamburgerMenu history={this.props.history} />;
  }
}

export default Menu;
