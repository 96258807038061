import React, { Component } from "react";
import news171 from "../../assets/img/news/news171.jpg";
import news172 from "../../assets/img/news/news172.jpg";
import news173 from "../../assets/img/news/news173.jpg";
import news174 from "../../assets/img/news/news174.jpg";
import news175 from "../../assets/img/news/news175.jpg";
import news176 from "../../assets/img/news/news176.jpg";
import { Link } from "react-router-dom";

import { connect } from "react-redux";

class Asection extends Component {
  state = {
    content: [],
    trend: [],
  };

  async componentDidMount() {
    const a = await this.props.trendSingle;
    this.setState({ trend: a.data });
    window.scrollTo(0, 0);
  }

  render() {
    const ct = this.state.trend;
    var ar = [];

    ct.map((r) => {
      r.map((t) => {
        ar.push(t);
      });
    });

    return (
      <div class="sidebar-box">
        <div class="topic-border color-cod-gray mb-5">
          <div class="topic-box-lg color-cod-gray">Recent News</div>
        </div>
        <div class="row">
          {ar &&
            ar.length > 0 &&
            ar.map((p) => {
              return (
                <div class="col-lg-6 col-md-4 col-sm-6 col-6">
                  <div class="mt-25 position-relative">
                    <div class="topic-box-top-xs">
                      <div class="topic-box-sm color-cod-gray mb-20">
                        {p.category}
                      </div>
                    </div>
                    <div to="#" class="mb-10 display-block img-opacity-hover">
                      <img src={p.imageURL} alt="ad" class="recent-side" />
                    </div>
                    <h3 class="title-medium-dark size-md mb-none">
                      <Link to={{ pathname: "/sports1", state: { p } }}>
                        {p.title}
                      </Link>
                    </h3>
                  </div>
                </div>
              );
            })}

          {/* <div class="col-lg-6 col-md-4 col-sm-6 col-6">
                        <div class="mt-25 position-relative">
                            <div class="topic-box-top-xs">
                                <div class="topic-box-sm color-cod-gray mb-20">Application</div>
                            </div>
                            <a href="" class="mb-10 display-block img-opacity-hover">
                                <img src={news172} alt="ad" class="img-fluid m-auto width-100"/>
                            </a>
                            <h3 class="title-medium-dark size-md mb-none">
                                <a href="">Rosie Huntington Whitl Habits Career Art.Rosie TBeauty Habits.</a>
                            </h3>
                        </div>
                    </div> */}

          {/* <div class="col-lg-6 col-md-4 col-sm-6 col-6">
                        <div class="mt-25 position-relative">
                            <div class="topic-box-top-xs">
                                <div class="topic-box-sm color-cod-gray mb-20">Life Style</div>
                            </div>
                            <a href="" class="mb-10 display-block img-opacity-hover">
                                <img src={news173} alt="ad" class="img-fluid m-auto width-100"/>
                            </a>
                            <h3 class="title-medium-dark size-md mb-none">
                                <a href="">Rosie Huntington Whitl Habits Career Art.Rosie TBeauty Habits.</a>
                            </h3>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-4 col-sm-6 col-6">
                        <div class="mt-25 position-relative">
                            <div class="topic-box-top-xs">
                                <div class="topic-box-sm color-cod-gray mb-20">Technology</div>
                            </div>
                            <a href="" class="mb-10 display-block img-opacity-hover">
                                <img src={news174} alt="ad" class="img-fluid m-auto width-100"/>
                            </a>
                            <h3 class="title-medium-dark size-md mb-none">
                                <a href="">Rosie Huntington Whitl Habits Career Art.Rosie TBeauty Habits.</a>
                            </h3>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-4 col-sm-6 col-6">
                        <div class="mt-25 position-relative">
                            <div class="topic-box-top-xs">
                                <div class="topic-box-sm color-cod-gray mb-20">Accessories</div>
                            </div>
                            <a href="" class="mb-10 display-block img-opacity-hover">
                                <img src={news175} alt="ad" class="img-fluid m-auto width-100"/>
                            </a>
                            <h3 class="title-medium-dark size-md mb-none">
                                <a href="">Rosie Huntington Whitl Habits Career Art.Rosie TBeauty Habits.</a>
                            </h3>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-4 col-sm-6 col-6">
                        <div class="mt-25 position-relative">
                            <div class="topic-box-top-xs">
                                <div class="topic-box-sm color-cod-gray mb-20">Model</div>
                            </div>
                            <a href="" class="mb-10 display-block img-opacity-hover">
                                <img src={news176} alt="ad" class="img-fluid m-auto width-100"/>
                            </a>
                            <h3 class="title-medium-dark size-md mb-none">
                                <a href="">Rosie Huntington Whitl Habits Career Art.Rosie TBeauty Habits.</a>
                            </h3>
                        </div>
                    </div> */}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    trendSingle: state.trendSingle,
  };
};
export default connect(mapStateToProps)(Asection);
