import React, { Component } from "react";
import Input from "./common/input";
import Form from "./common/form";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Modal } from "react-responsive-modal";
import RegisterForm from "./registerform";
import ForgotPswd from "./forgotpswd";
import { Link, } from "react-router-dom";
import Joi, { errors } from "joi-browser";
import auth from "../services/authServices";
import SearchField from "react-search-field";
import { filter, toLower } from "lodash";
import facebookLogin from "facebook-login";
import { connect } from "react-redux";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";

class HamburgerMenu extends Form {
  state = {
    isOpen: false,
    open: false,
    open1: false,
    open2: false,
    open3: false,
    searchQuery: "",
    content: [],
    btn_dis: false,
    data: { email: "", password: "" },
    errors: {},
  };
  schema = {
    email: Joi.string().required().label("Email"),
    password: Joi.string().required().label("Password"),
  };

  async componentDidMount() {
    const a = await this.props.getContent;
    this.setState({ content: a.data });
    const user = auth.getCurrentUser();
    this.setState({ user });
  }



  doSubmit = async () => {
    await this.setState({btn_dis:true});
    try {
      const { data } = this.state;
      await auth.login(data.email, data.password);

      toast.success("Login successfully", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setTimeout(() => {
        window.location = "/";
      }, 3000);
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        await this.setState({btn_dis:false});
        toast.error("please enter valid email and password", {
          position: toast.POSITION.TOP_RIGHT,
        });

        const errors = { ...this.state.errors };
        errors.email = ex.response.data;
        this.setState({ errors });
      }
    }
  };

  onOpenModal = () => {
    this.setState({ open: true });
  };

  onOpenModal1 = () => {
    this.setState({ open1: true, open: false });
  };

  onOpenModal2 = () => {
    this.setState({ open2: true, open: false });
  };

  onCloseModal = () => {
    this.setState({ open: false, open1: false, open2: false });
  };

  handleSearch = (e) => {
    const query = e.currentTarget.value;
    this.setState({ searchQuery: query });
  };

  handlebtn = (z) => {
    if (!this.state.searchQuery) {
      return null;
    } else {
      let filtered = [];
      filtered = this.state.content.filter(
        (m) =>
          m.title
            .toLowerCase()
            .includes(this.state.searchQuery.toLowerCase()) ||
          m.category
            .toLowerCase()
            .includes(this.state.searchQuery.toLocaleLowerCase())
      );
      if (filtered.length > 0) {
        this.props.history.push({
          pathname: "/search",
          state: filtered,
        });
      } else {
        this.props.history.push({
          pathname: "/notfound",
        });
      }
    }
  };

  toggle = async () => {
    await this.setState({ isOpen: !this.state.isOpen });
  };

  render() {
    const { open, data, errors, user, searchQuery } = this.state;
    return (
      <React.Fragment>
        <div className="container p-0">
          <Navbar color="#ffff" light expand="md" style={{backgroundcolor:"#ffffff !important; padding : 0px;"}}>
            <NavbarToggler onClick={this.toggle} />
            <Collapse isOpen={this.state.isOpen} navbar>
              <Nav className="me-auto" navbar pt-md-2>
                <NavItem>
                  <NavLink>
                    <Link
                      className="nav-link active"
                      // aria-current="page"
                      to="/"
                    >
                      HOME
                    </Link>
                  </NavLink>
                </NavItem>

                <NavItem>
                  <NavLink>
                    <Link
                      className="nav-link"
                      to="/posts"
                      tabindex="-1"
                      // aria-disabled="true"
                    >
                      POSTS
                    </Link>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink>
                    <Link
                      className="nav-link"
                      to="/politics"
                      tabindex="-1"
                      aria-disabled="true"
                    >
                      POLITICS
                    </Link>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink>
                    <Link
                      className="nav-link"
                      to="/business"
                      tabindex="-1"
                      aria-disabled="true"
                    >
                      BUSINESS
                    </Link>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink>
                    <Link
                      className="nav-link"
                      to="/sports"
                      tabindex="-1"
                      aria-disabled="true"
                    >
                      SPORTS
                    </Link>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink>
                    <Link
                      className="nav-link"
                      to="/fashion"
                      tabindex="-1"
                      aria-disabled="true"
                    >
                      FASHION
                    </Link>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink>
                    <Link
                      className="nav-link"
                      to="/travel"
                      tabindex="-1"
                      aria-disabled="true"
                    >
                      TRAVEL
                    </Link>
                  </NavLink>
                </NavItem>

                <NavItem>
                  <NavLink>
                    <div class="input-group  offset-lg-11 mb-input-search">
                      <input
                        type="search"
                        class="form-control rounded "
                        placeholder="Search"
                        aria-label="Search"
                        aria-describedby="search-addon"
                        value={searchQuery}
                        onChange={this.handleSearch}
                      />
                      <button
                      disabled={this.state.btn_dis}
                        type="button"
                        onClick={() => this.handlebtn()}
                        class="btn btg-red"
                      >
                        <i className="fa fa-search"></i>
                      </button>
                    </div>
                  </NavLink>
                </NavItem>

              </Nav>

              <NavLink>
                <div className="input-group p-1 row m-2">    

                {/* {user && user.isAdmin ? (
            <React.Fragment>
              <NavLink className="nav-item nav-link" to="/todolist">
              
              </NavLink>
            </React.Fragment>
          ):null
        } */}
  

                  {user ? (
                    <div class="offset-md-2 ">
                      <h5 className="login-btn text-dark mb-input-search">
                        <i className="fa fa-user p-2" aria-hidden="true"></i>
                        <span
                          class="d-name d-flex"
                          id="dropdownMenuButton"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          {user.name}
                        </span>

                        <div class="dropdown">
                          <div
                            class="dropdown-menu"
                            aria-labelledby="dropdownMenuButton"
                          >
                            <Link class="dropdown-item" to="/Logout">
                              Logout
                            </Link>
                          </div>
                        </div>
                      </h5>
                    </div>
                  ) : (
                    <button
                      onClick={this.onOpenModal}
                      className="login-btn text-dark mb-input-search"
                    >
                      <i className="fa fa-user" aria-hidden="true"></i>
                      Sign in
                    </button>
                  )}

                  <div className="login-media">
                    <Modal open={open} onClose={this.onCloseModal}>
                      <div className="Modal-content">
                        <div className="modal-header">
                          <div className="title-login-form">Login</div>
                        </div>
                        <div className="modal-body">
                          <div className="login-form">
                            <form onSubmit={this.handleSubmit}>
                              <Input
                                name="email"
                                value={data.email}
                                placeholder="Email"
                                label="Email"
                                onChange={this.handleChange}
                                error={errors.email}
                              />

                              <Input
                                name="password"
                                type="password"
                                placeholder="Password"
                                value={data.password}
                                label="Password"
                                onChange={this.handleChange}
                                error={errors.password}
                              />

                              <div className="checkbox checkbox-primary">
                                <input id="checkbox" type="checkbox" />
                                <label
                                  className="form-check-label"
                                  for="checkbox"
                                >
                                  Remember Me
                                </label>
                              </div>

                              <button
                              disabled={this.state.btn_dis}
                                type="submit"
                                className="login"
                                value="Login"
                              >
                                Login
                              </button>

                              {/* <label className="lost-password">
                                  <Link 
                                  onClick={this.onOpenModal2}>
                                   <a href="forgotpswd">
                                   Forgotten password?
                                     </a> 
                                  </Link>
                                </label> */}

                              <p className="text-muted">
                                Don't have an account?{" "}
                                <Link
                                  onClick={this.onOpenModal1}
                                  className="text-primary ml-1"
                                >
                                  <b>
                                    <a href="registerform">Sign Up</a>
                                  </b>
                                </Link>
                              </p>
                            </form>
                          </div>
                        </div>
                      </div>
                    </Modal>
                  </div>

                  <Modal open={this.state.open1} onClose={this.onCloseModal}>
                    <div className="Modal-content reg">
                      <div className="modal-header">
                        <div className="title-login-form">Register</div>
                      </div>
                      <div className="modal-body">
                        <RegisterForm />
                      </div>
                    </div>
                  </Modal>

                  <Modal open={this.state.open2} onClose={this.onCloseModal}>
                    <div className="Modal-content reg">
                      <div className="modal-header">
                        {/* <div className="title-login-form"></div> */}
                      </div>
                      <div className="modal-body">
                        <ForgotPswd />
                      </div>
                    </div>
                  </Modal>
                </div>
              </NavLink>
            </Collapse>
          </Navbar>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    getContent: state.getContent,
  };
};
export default connect(mapStateToProps)(HamburgerMenu);
