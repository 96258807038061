import React, { Component } from "react";
import Header from "../Header";
import Menu from "../menu";
import Marquee from "react-double-marquee";
import { Link, Redirect } from "react-router-dom";
import Footer from "../footer";
import Footercontain from "../footercontain";
import Textscroll from "../textscroll";
import Article from "./Article";

class Sports1 extends Component {
  state = {};

  render() {
    const ct = this.props.history.location.state.p;
    return (
      <React.Fragment>
        <Header />
        <Menu history={this.props.history} />
        <Textscroll />
        <section id="img" class="breadcrumbs-area">
          <div class="container">
            <div class="breadcrumbs-content">
              <h1> {ct.category} </h1>
              <ul>
                <li>
                  <Link to="/">Home</Link> -
                </li>
                <li>{/* <a href="#">Sports</a> */}-</li>
                <li>Single post </li>
              </ul>
            </div>
          </div>
        </section>
        <Article history={this.props.history} />
        <Footercontain />
        <Footer />
      </React.Fragment>
    );
  }
}

export default Sports1;
