import React, { Component } from "react";
import news19 from "../assets/img/news/news19.jpg";
import news20 from "../assets/img/news/news20.jpg";
import news21 from "../assets/img/news/news21.jpg";
import news22 from "../assets/img/news/news22.jpg";
import news23 from "../assets/img/news/news23.jpg";
import news24 from "../assets/img/news/news24.jpg";
import news25 from "../assets/img/news/news25.jpg";
import news26 from "../assets/img/news/news26.jpg";
import news27 from "../assets/img/news/news27.jpg";
import news28 from "../assets/img/news/news28.jpg";
import news29 from "../assets/img/news/news29.jpg";
import news30 from "../assets/img/news/news30.jpg";
import banner2 from "../assets/img/banner/banner2.jpg";
import gif from "../assets/img/gif.png";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

class News extends Component {
  state = {
    content: [],
  };

  async componentDidMount() {
    const a = await this.props.trendContent;
    this.setState({ content: a.data });
  }

  render() {
    const ct = this.state.content[0];
    const bt = this.state.content[1];
    const pt = this.state.content[2];

    return (
      <section class="bg-body section-space-less30 pt-0">
        <div class="container">
          <div class="row">
            <div class="col-lg-4 col-md-12">
              <div class="topic-border color-cutty-sark mb-30 width-100">
                <div class="topic-box-lg color-cutty-sark">Sports</div>
              </div>
              <div class="img-overlay-70 img-scale-animate mb-30">
                <div class="mask-content-sm">
                  <div class="post-date-light">
                    <ul>
                      <li>
                        <span>by</span>
                        <Link to="">Adams</Link>
                      </li>
                      <li>
                        <span>
                          <i class="fa fa-calendar" aria-hidden="true"></i>
                        </span>
                        {ct && ct.length > 0 ? ct[1].date : null}
                      </li>
                    </ul>
                  </div>
                  <h3 class="title-medium-light">
                    <Link
                      to={{
                        pathname: "/sports1",
                        state: { p: ct && ct.length > 0 ? ct[1] : null },
                      }}
                    >
                      {ct && ct.length > 0 ? ct[1].title : null}
                    </Link>
                  </h3>
                </div>
                {ct && ct.length > 0 ? (
                  <img
                    src={ct.length > 0 ? ct[1].imageURL : gif}
                    alt="news"
                    class="media-md"
                  />
                ) : null}
              </div>

              {ct &&
                ct.length > 0 &&
                ct.slice(2, 5).map((p, index) => {
                  return (
                    <div class="media mb-30">
                      <Link class="img-opacity-hover media-sm" to="">
                        <img src={p.imageURL} alt="news" class="" />
                      </Link>
                      <div class="media-cat">
                        <div class="post-date-dark">
                          <ul>
                            <li>
                              <span>
                                <i
                                  class="fa fa-calendar"
                                  aria-hidden="true"
                                ></i>
                              </span>
                              {p.date}
                            </li>
                          </ul>
                        </div>
                        <h3 class="title-medium-dark size-md mb-none">
                          <Link to={{ pathname: "/sports1", state: { p } }}>
                            {p.title}
                          </Link>
                        </h3>
                      </div>
                    </div>
                  );
                })}
            </div>

            {/* //Business */}

            <div class="col-lg-4 col-md-12">
              <div class="topic-border color-pomegranate mb-30 width-100">
                <div class="topic-box-lg color-pomegranate">Business</div>
              </div>
              <div class="img-overlay-70 img-scale-animate mb-30">
                <div class="mask-content-sm">
                  <div class="post-date-light">
                    <ul>
                      <li>
                        <span>by</span>
                        <Link to="">Adams</Link>
                      </li>
                      <li>
                        <span>
                          <i class="fa fa-calendar" aria-hidden="true"></i>
                        </span>
                        {bt && bt.length > 0 ? bt[2].date : null}
                      </li>
                    </ul>
                  </div>
                  <h3 class="title-medium-light">
                    <Link
                      to={{
                        pathname: "/sports1",
                        state: { p: bt && bt.length > 0 ? bt[2] : null },
                      }}
                    >
                      {bt && bt.length > 0 ? bt[2].title : null}
                    </Link>
                  </h3>
                </div>
                {bt && bt.length > 0 ? (
                  <img
                    src={bt.length > 0 ? bt[2].imageURL : gif}
                    alt="news"
                    class="media-md"
                  />
                ) : null}
              </div>

              {bt &&
                bt.length > 0 &&
                bt.slice(3, 6).map((p, index) => {
                  return (
                    <div class="media mb-30">
                      <Link class="img-opacity-hover media-sm" to="">
                        <img src={p.imageURL} alt="news" class="" />
                      </Link>
                      <div class="media-cat">
                        <div class="post-date-dark">
                          <ul>
                            <li>
                              <span>
                                <i
                                  class="fa fa-calendar"
                                  aria-hidden="true"
                                ></i>
                              </span>
                              {p.date}
                            </li>
                          </ul>
                        </div>
                        <h3 class="title-medium-dark size-md mb-none">
                          <Link to={{ pathname: "/sports1", state: { p } }}>
                            {p.title}
                          </Link>
                        </h3>
                      </div>
                    </div>
                  );
                })}
            </div>

            <div class="col-lg-4 col-md-12">
              <div class="topic-border color-web-orange mb-30 width-100">
                <div class="topic-box-lg color-web-orange">Politics</div>
              </div>
              <div class="img-overlay-70 img-scale-animate mb-30">
                <div class="mask-content-sm">
                  <div class="post-date-light">
                    <ul>
                      <li>
                        <span>by</span>
                        <Link to="">Adams</Link>
                      </li>
                      <li>
                        <span>
                          <i class="fa fa-calendar" aria-hidden="true"></i>
                        </span>
                        {pt && pt.length > 0 ? pt[1].date : null}
                      </li>
                    </ul>
                  </div>
                  <h3 class="title-medium-light">
                    <Link
                      to={{
                        pathname: "/sports1",
                        state: { p: pt && pt.length > 0 ? pt[1] : null },
                      }}
                    >
                      {pt && pt.length > 0 ? pt[1].title : null}
                    </Link>
                  </h3>
                </div>
                {pt && pt.length > 0 ? (
                  <img
                    src={pt.length > 0 ? pt[1].imageURL : gif}
                    alt="news"
                    class="media-md"
                  />
                ) : null}
              </div>

              {pt &&
                pt.length > 0 &&
                pt.slice(2, 5).map((p, index) => {
                  return (
                    <div class="media mb-30">
                      <Link class="img-opacity-hover media-sm" to="">
                        <img src={p.imageURL} alt="news" class="" />
                      </Link>
                      <div class="media-cat">
                        <div class="post-date-dark">
                          <ul>
                            <li>
                              <span>
                                <i
                                  class="fa fa-calendar"
                                  aria-hidden="true"
                                ></i>
                              </span>
                              {p.date}
                            </li>
                          </ul>
                        </div>
                        <h3 class="title-medium-dark size-md mb-none">
                          <Link to={{ pathname: "/sports1", state: { p } }}>
                            {p.title}
                          </Link>
                        </h3>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>

          {/* <div class="row">
            <div class="col-12">
              <div class="ne-banner-layout1 mb-50 mt-20-r text-center">
                <a href="#">
                  <img src={banner2} alt="ad" class="img-fluid" />
                </a>
              </div>
            </div>
          </div> */}
        </div>
      </section>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    trendContent: state.trendContent,
  };
};
export default connect(mapStateToProps)(News);
