import http from "./httpService";
import { apiUrl } from "../config.json";

const apiEndpoint = apiUrl + "/content";

const apiEndpoint1 = apiUrl + "/content/delete";

const apiEndpoint2 = apiUrl + "/content/update";

export function posting(news) {
  return http.post(apiEndpoint, {
    title: news.title,
    category: news.category,
    description: news.description,
    imageURL: news.imageURL,
    video: news.video,
    date: news.date,
    time: news.time,
  });
}

export function getContent() {
  return http.get(apiEndpoint);
}

export function updating(obj) {
  return http.post(apiEndpoint2, obj);
}

export function deleting(id) {
  return http.post(apiEndpoint1, id);
}

export default {
  getContent,
  posting,
  deleting,
  updating,
};
