import React, { Component } from "react";
import Header from "../Header";
import Menu from "./menu";
import RichTextEditor from "react-rte";
import Footer from "../footer";

class Trending extends Component {
  static propTypes = {
    onChange: Trending.func,
  };
  state = {
    value: RichTextEditor.createEmptyValue(),
  };
  onChange = (value) => {
    this.setState({ value });
    if (this.props.onChange) {
      this.props.onChange(value.toString("html"));
    }
  };

  render() {
    const toolbarConfig = {
      display: [
        "INLINE_STYLE_BUTTONS",
        "BLOCK_TYPE_BUTTONS",
        "LINK_BUTTONS",
        "BLOCK_TYPE_DROPDOWN",
        "HISTORY_BUTTONS",
      ],
      INLINE_STYLE_BUTTONS: [
        { label: "Bold", style: "BOLD", className: "custom-css-class" },
        { label: "Italic", style: "ITALIC" },
        { label: "Underline", style: "UNDERLINE" },
      ],
      BLOCK_TYPE_DROPDOWN: [
        { label: "Normal", style: "unstyled" },
        { label: "Heading Large", style: "header-one" },
        { label: "Heading Medium", style: "header-two" },
        { label: "Heading Small", style: "header-three" },
      ],
      BLOCK_TYPE_BUTTONS: [
        { label: "UL", style: "unordered-list-item" },
        { label: "OL", style: "ordered-list-item" },
      ],
    };
    return (
      <React.Fragment>
        <Header />
        <Menu />
        <div className="bg-accent section-space-bottom-less4 pt-20">
        <div className="container">
          <form>
            <div class="mb-3">
              <label for="exampleInputLink" class="form-label">
                Link
              </label>
              <input type="url" class="form-control" id="exampleInputTitle" />
            </div>
            <div class="mb-3">
              <label for="floatingTextarea">Description</label>
              <RichTextEditor
                toolbarConfig={toolbarConfig}
                value={this.state.value}
                onChange={this.onChange}
                rows="5"
              />
            </div>
            <div class="mb-3">
              <label for="exampleInputTitle" class="form-label">
                Title
              </label>
              <input
                type="Title"
                class="form-control"
                id="exampleInputTitle"
                aria-describedby="emailHelp"
              />
            </div>
            <button type="submit" class="btn btn-primary">
              Submit
            </button>
          </form>
        </div>
        </div>
        <Footer />
      </React.Fragment>
    );
  }
}

export default Trending;
