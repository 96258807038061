import { UPDATE_GETCONTENT } from "./../action/postAction";
import { getContent } from "./../../services/postServices";

const initialState = getContent();
const allContent = (state = initialState, { type, payload }) => {
  switch (type) {
    case UPDATE_GETCONTENT:
      return payload;
    default:
      return state;
  }
};
export default allContent;
