import React, { Component } from "react";
import news177 from "../../assets/img/news/news177.jpg";
import news178 from "../../assets/img/news/news178.jpg";
import Main from "./main";
import { Link } from "react-router-dom";
import Comment from "./comment";
import { connect } from "react-redux";
import LiveComments from "./livecomments";

class Article extends Component {
  state = {
    content: [],
  };


  componentDidMount() {
    window.scrollTo(0, 0);
    }

  render() {
    const ct = this.props.history.location.state.p;

    return (
      <section class="bg-body section-space-less30">
        <div class="container">
          <div class="row">
            <div class="col-lg-8 col-md-12 mb-30">
              <div class="news-details-layout1">
                <div class="position-relative mb-30">
                  <img
                    src={ct.imageURL}
                    alt="news-details"
                    class="single-img"
                  />
                  <div class="topic-box-top-sm">
                    <div class="topic-box-sm color-cinnabar mb-20">
                      {ct.category}
                    </div>
                  </div>
                </div>
                <h2 class="title-semibold-dark size-c30">{ct.title}</h2>
                <ul class="post-info-dark mb-30">
                    <h4>
                  <li>
                    {/* <Link to=""> */}
                    <span>By</span> Mark Willy
                    {/* </Link> */}
                  </li>
                  <li>
                    {/* <Link to=""> */}
                    <i class="fa fa-calendar m-1" aria-hidden="true"></i>
                    {ct.date}
                    {/* </Link> */}
                  </li>
                  <li>
                    {/* <Link to=""> */}
                    <i class="fa fa-eye" aria-hidden="true"></i>202
                    {/* </Link> */}
                  </li>
                  <li>
                    {/* <Link to=""> */}
                    <i class="fa fa-comments" aria-hidden="true"></i>20
                    {/* </Link> */}
                  </li>
                  </h4>
                </ul>
                <p>{ct.description}</p>
                {/* <p>{ct.description}</p> */}
                {/* <img src={ct.imageURL} alt="news-details" class="img-fluid pull-left mr-40 mb-15"/> */}
                {/* <p>
                  Nmply dummy text of the printing and typesetting ustry. Lorem
                  Ipsum has been the industry's stydedy andard dummy text ever
                  since the, when new wwan printer took a galley of type
                  andsercrambled it toit make a type specimen book. It has
                  survived anneyt only five centuries, but also theleap into
                  electro nic typesetting, remaining.
                </p>
                <p>
                  It is a long established fact that a reader will be dist
                  racted by the readable content of a page when looking at its
                  layout.
                </p> */}
                <blockquote cite="#" class="bg-accent mt-50 mb-50">
                  Srem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s. Lorem Ipsum is
                  simply .
                </blockquote>
                {/* <p>
                  Nmply dummy text of the printing and typesetting industry.
                  Lorem Ipsum has been the industry's st andard dummy text ever
                  since the 1500s, when an unknown printer took a galley of type
                  andse aerr crambled it to make a type specimen book. It has
                  survived not only five centuries, but also they area leap into
                  electronic typesetting, remaining.
                </p> */}
                <ul class="blog-tags item-inline">
                  <li>Tags</li>
                  <li>
                    <Link to="/">#Sports</Link>
                  </li>
                  <li>
                    <Link to="/">#Magazine</Link>
                  </li>
                  <li>
                    <Link to="/">#Lifestyle</Link>
                  </li>
                </ul>

                <div class="post-share-area mb-40 item-shadow-1">
                  <p>You can share this post!</p>
                  <ul class="social-default item-inline">
                    <li>
                      <a
                        to="https://www.facebook.com/"
                        target="_blank"
                        class="facebook"
                        title="facebook"
                      >
                        <i className="fa fa-facebook" aria-hidden="true"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://twitter.com/LOGIN"
                        target="_blank"
                        class="twitter"
                        title="twitter"
                      >
                        <i className="fa fa-twitter" aria-hidden="true"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://accounts.google.com/login"
                        target="_blank"
                        class="google"
                      >
                        <i class="fa fa-google-plus" aria-hidden="true"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://in.pinterest.com/login/"
                        class="pinterest"
                        target="_blank"
                      >
                        <i class="fa fa-pinterest" aria-hidden="true"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://feeder.co/login"
                        target="_blank"
                        class="rss"
                      >
                        <i class="fa fa-rss" aria-hidden="true"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.linkedin.com/login"
                        target="_blank"
                        class="linkedin"
                      >
                        <i class="fa fa-linkedin" aria-hidden="true"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>

              <LiveComments />
              <Comment />
            </div>
            <Main />
          </div>
        </div>
      </section>
    );
  }
}

export default Article;
