import React, { Component } from "react";
import Header from "../Header";
import news19 from "../../assets/img/news/news19.jpg";
import Menu from "./menu";
import Footer from "../footer";

class Gallery extends Component {
  state = {};
  render() {
    return (
      <React.Fragment>
        <Header />
        <Menu />
        <div className="container">
          <form onSubmit={this.onFormSubmit}>
            <h1>File Upload</h1>
            <input type="file" name="myImage" onChange={this.onChange} />
            <button type="submit">Upload</button>
          </form>
        </div>
        {/* <Footer/> */}
      </React.Fragment>
    );
  }
}

export default Gallery;
