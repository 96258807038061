import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import 'bootstrap/dist/css/bootstrap.css';
import "./assets/css/normalize.css";
import "./assets/css/main.css";
import "./assets/vendor/OwlCarousel/owl.carousel.min.css";
import "./assets/vendor/OwlCarousel/owl.theme.default.min.css";
import "./assets/css/meanmenu.min.css";
import "./assets/css/magnific-popup.css";
import "./assets/style.css";
import "./assets/css/ie-only.css";
import "./assets/css/bootstrap.min.css";
import "./assets/css/font-awesome.min.css";
import "./assets/css/select2.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import *as serviceWorker from "./serviceWorker";
import store from './redux/store/index';



ReactDOM.render(
<Provider store={store}>
<BrowserRouter>
<App />
</BrowserRouter>
</Provider>,
document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();