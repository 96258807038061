import React, { Component } from "react";
import play from "../assets/img/banner/play.png";
// import news16 from "../assets/img/news/news16.jpg";
// import news17 from "../assets/img/news/news17.jpg";
// import news18 from "../assets/img/news/news18.jpg";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Link } from "react-router-dom";
import YouTube from "react-youtube";
import getYouTubeID from "get-youtube-id";
import ModalVideo from "react-modal-video";
import { connect } from "react-redux";
import { Card } from "react-bootstrap";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    slidesToSlide: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    slidesToSlide: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
};
class Videos extends Component {
  state = {
    content: [],
    isOpen: false,
    video_id: "",
    data: [],
  };

  async componentDidMount() {
    // await this.setState({ data: data });
    const a = await this.props.getContent;
    this.setState({ content: a.data });
  }

  openModal(k) {
    var id = getYouTubeID(k.video);

    this.setState({ isOpen: true, video_id: id });
  }

  render() {
    const ar = [];
    const ct = this.state.content.filter((content) => {
      ar.push({
        title: content.title,
        video: content.video,
        category: content.category,
        imageURL: content.imageURL,
      });
    });

    const k = this.state.content
      ? this.state.content.filter((content) => {
          return content.video;
        })
      : [];

    const c = this.state.content.filter((content) => {
      return content.category.includes("Sports");
    });

    return (
      <section class="bg-accent ">
        <div class="container pt-5 mt-5">
          <div class="row tab-space-2">
            {ar && ar.length > 0 ? (
              <Carousel
                showDots={false}
                autoPlay={true}
                responsive={responsive}
                removeArrowOnDeviceType={["desktop", "mobile"]}
                // itemClass="carousel-item-padding-100-px"
              >
                {ar.map((p) => {
                  return (
                    <div
                      class="col-lg-4 col-md-12 mb-4"
                      onClick={() => this.openModal(p)}
                    >
                      <Card className="bg-dark text-white ">
                        <Card.Img
                          src={p.imageURL}
                          alt="Card image"
                          class="videos-slider"
                        />
                        <Card.ImgOverlay>
                          <Card.Title>
                            <div class="text-center">
                              <a class="play-btn popup-youtube">
                                <img src={play} alt="play" class="img-fluid" />
                              </a>
                            </div>

                            <div class="videos_content">
                              <div class="topic-box-sm color-pomegranate mb-20">
                                {p.category}
                              </div>
                              <h3 class="title-medium-light">
                                <Link
                                  to={{ pathname: "/sports1", state: { p } }}
                                >
                                  {p.title}
                                </Link>
                              </h3>
                            </div>
                          </Card.Title>
                        </Card.ImgOverlay>
                      </Card>
                      {/* <div class="img-overlay-70">
                        
                          <div class="topic-box-sm color-pomegranate mb-20">
                            {p.cat}
                          </div>
                          <h3 class="title-medium-light">
                            <a href="">{p.title}</a>
                          </h3>
                        
                        <div class="text-center">
                          <a class="play-btn popup-youtube">
                            <img src={play} alt="play" class="img-fluid" />
                          </a>
                        </div>
                        <img
                          src={p.img}
                          alt="news"
                          class=""
                          style={{width:'500px'}}
                          
                        />
                      </div> */}
                    </div>
                  );
                })}
              </Carousel>
            ) : null}
          </div>
        </div>
        <ModalVideo
          channel="youtube"
          isOpen={this.state.isOpen}
          videoId={this.state.video_id}
          onClose={() => this.setState({ isOpen: false })}
        />
      </section>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    getContent: state.getContent,
  };
};
export default connect(mapStateToProps)(Videos);
