import React, { Component } from "react";
import Header from "../Header";
import news19 from "../../assets/img/news/news19.jpg";
import * as postService from "../../services/postServices";
import Menu from "./menu";
import Footer from "../footer";

import { connect } from "react-redux";

class Allposts extends Component {
  state = {
    content: [],
  };

  async componentDidMount() {
    const a = await this.props.getContent;
    console.log(a.data.length);
    this.setState({ content: a.data });
  }

  handleDelete = async (e) => {
    
    try{
      const obj ={
        id:e._id
      }

      const del=  await postService.deleting(obj);
      if(del.data){
  
        const content = this.state.content.filter((c) => c._id !== e._id);
    
        this.setState({ content });
    }
    }
    catch{

    }
  };

  handleEdit = (e) => {
    this.props.history.push({
      pathname: "/editingpage",
      state: { data: e },
    });
  };

  render() {
    const ct = this.state.content;

    return (
      <React.Fragment>
        <Header />
        <Menu />

        <section>
          <div class="container-fluid">
          <table className="table" style={{ marginTop: 30 }}>
            <thead>
              <tr>
                <th scope="col">Post Title</th>
                <th scope="col">Description</th>
                <th scope="col">Category</th>
                <th scope="col">Date</th>
                <th scope="col">Time</th>
                <th scope="col">Image</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              {this.state.content.map((p) => {
              
                return (
                  <tr key={p._id}>


                 
                    <td>{p.title}</td>
                    <td>{p.description >500 ? p.description.slice(0,500) + '...' : p.description}</td>
                    <td>{p.category}</td>
                    <td>{p.time}</td>
                    <td>{p.date}</td>
                    <td>
                    
                      <img
                        src={p.imageURL}
                        alt="news"
                        class="mt-0"
                        style={{ height: "60px", width: "80px" }}
                      />
                    </td>
                    
                    <td nowrap>
                      <button
                        onClick={() => this.handleEdit(p)}
                        type="button"
                        class="btn btn-primary mb-2"
                      >
                        Edit
                      </button>
                      <button
                        onClick={() => this.handleDelete(p)}
                        type="button"
                        class="btn btn-danger mb-2"
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
       
          </div> </section>
        <Footer />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    getContent: state.getContent,
  };
};
export default connect(mapStateToProps)(Allposts);

