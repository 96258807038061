import React, { Component } from "react";
import post1 from "../assets/img/footer/post1.jpg";
import post2 from "../assets/img/footer/post2.jpg";
import post3 from "../assets/img/footer/post3.jpg";
import post4 from "../assets/img/footer/post4.jpg";
import post5 from "../assets/img/footer/post5.jpg";
import post6 from "../assets/img/footer/post6.jpg";
import post7 from "../assets/img/footer/post7.jpg";
import post8 from "../assets/img/footer/post8.jpg";
import post9 from "../assets/img/footer/post9.jpg";
import post10 from "../assets/img/footer/post10.jpg";
import post11 from "../assets/img/footer/post11.jpg";
import post12 from "../assets/img/footer/post12.jpg";
import { Link } from "react-router-dom";
import { connect } from "react-redux";


class Fcontain extends Component {
  state = {
    trend:[],
  };
  
  async componentDidMount() {
 

    const a = await this.props.trendSingle;
    this.setState({ trend: a.data });
  }
  render() {

    const z = this.state.trend;

    var ar1 = [];

    z.map((r) => {
      r.map((t) => {
        ar1.push(t);
      });
    });

  

    return (
      <footer>
        <div className="footer-area-top">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="footer-box">
                  <h2 className="title-bold-light title-bar-left text-uppercase">
                    Most Viewed Posts
                  </h2>
                  {ar1 &&
                    ar1.length > 0 &&
                    ar1.slice(0,3).map((p) => {
                      return (
                  <ul className="most-view-post">
                    <li>
                      <div className="media">
                        {/* <Link to="/"> */}
                        <span class="media-sm">
                          <img
                            src={p.imageURL}
                            alt="post"
                            
                          />
                          </span>
                        {/* </Link> */}
                        <div className="media-cat">
                          <h3 className="title-medium-light size-md mb-10">
                          <Link
                                to={{
                                  pathname: "/sports1",
                                  state: { p },
                                }}
                              >
                                {p.title}
                              </Link>
                          </h3>
                          <div className="post-date-light">
                            <ul>
                              <li>
                                <span>
                                  <i
                                    className="fa fa-calendar"
                                    aria-hidden="true"
                                  ></i>
                                </span>
                               {p.date}
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </li>
                    

                  </ul>
                  )})}
                </div>
              </div>
              <div className="col-xl-4 col-lg-3 col-md-6 col-sm-12">
                <div className="footer-box">
                  <h2 className="title-bold-light title-bar-left text-uppercase">
                    Popular Categories
                  </h2>
                  <ul className="popular-categories">
                    <li>
                      <Link to="/">
                        Gadgets
                        <span>15</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/">
                        Architecture
                        <span>10</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/">
                        New look 2017
                        <span>14</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/">
                        Reviews
                        <span>13</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/">
                        Mobile and Phones
                        <span>19</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/">
                        Recipes
                        <span>26</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/">
                        Decorating
                        <span>21</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/">
                        IStreet fashion
                        <span>09</span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-xl-4 col-lg-5 col-md-12 col-sm-12">
                <div className="footer-box">
                  <h2 className="title-bold-light title-bar-left text-uppercase">
                    Post Gallery
                  </h2>
                  <ul className="post-gallery shine-hover ">
                    <li>
                      <div>
                        <figure>
                          <img src={post4} alt="post" className="img-fluid" />
                        </figure>
                      </div>
                    </li>
                    <li>
                      <div>
                        <figure>
                          <img src={post5} alt="post" className="img-fluid" />
                        </figure>
                      </div>
                    </li>
                    <li>
                      <div>
                        <figure>
                          <img src={post6} alt="post" className="img-fluid" />
                        </figure>
                      </div>
                    </li>
                    <li>
                      <div>
                        <figure>
                          <img src={post7} alt="post" className="img-fluid" />
                        </figure>
                      </div>
                    </li>
                    <li>
                      <div>
                        <figure>
                          <img src={post8} alt="post" className="img-fluid" />
                        </figure>
                      </div>
                    </li>
                    <li>
                      <div>
                        <figure>
                          <img src={post9} alt="post" className="img-fluid" />
                        </figure>
                      </div>
                    </li>
                    <li>
                      <div>
                        <figure>
                          <img src={post10} alt="post" className="img-fluid" />
                        </figure>
                      </div>
                    </li>
                    <li>
                      <div>
                        <figure>
                          <img src={post11} alt="post" className="img-fluid" />
                        </figure>
                      </div>
                    </li>
                    <li>
                      <div>
                        <figure>
                          <img src={post12} alt="post" className="img-fluid" />
                        </figure>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    trendSingle: state.trendSingle,
  };
};
export default connect(mapStateToProps)(Fcontain);
