import React, { Component } from "react";

class Aside extends Component {
  state = {};
  render() {
    return (
      <div class="ne-sidebar sidebar-break-md col-lg-4 col-md-12">
        <div class="sidebar-box">
          <div class="topic-border color-cod-gray mb-25">
            <div class="topic-box-lg color-cod-gray">Tags</div>
          </div>
          <ul class="sidebar-tags">
            <li>
              <a href="#">Apple</a>
            </li>
            <li>
              <a href="#">Business</a>
            </li>
            <li>
              <a href="#">Architecture</a>
            </li>
            <li>
              <a href="#">Gadgets</a>
            </li>
            <li>
              <a href="#">Software</a>
            </li>
            <li>
              <a href="#">Microsoft</a>
            </li>
            <li>
              <a href="#">Robotic</a>
            </li>
            <li>
              <a href="#">Technology</a>
            </li>
            <li>
              <a href="#">Others</a>
            </li>
          </ul>
        </div>

        <div class="sidebar-box">
          <div class="topic-border color-cod-gray mb-30">
            <div class="topic-box-lg color-cod-gray">Stay Connected</div>
          </div>
          <ul class="stay-connected overflow-hidden">
            <li class="facebook">
              <a href="#">
                <i class="fa fa-facebook" aria-hidden="true"></i>
                <div class="connection-quantity">50.2 k</div>
                <p>Fans</p>
              </a>
            </li>
            <li class="twitter">
              <a href="#">
                <i class="fa fa-twitter" aria-hidden="true"></i>
                <div class="connection-quantity">10.3 k</div>
                <p>Followers</p>
              </a>
            </li>
            <li class="linkedin">
              <a href="#">
                <i class="fa fa-linkedin" aria-hidden="true"></i>
                <div class="connection-quantity">25.4 k</div>
                <p>Fans</p>
              </a>
            </li>
            <li class="rss">
              <a href="#">
                <i class="fa fa-rss" aria-hidden="true"></i>
                <div class="connection-quantity">20.8 k</div>
                <p>Subscriber</p>
              </a>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

export default Aside;
