import { combineReducers } from "redux";
import postReducer from "./postReducer";
import trendReducer from "./trendReducer";
import trendSingle from "./trendsingle";

export default function allReducers() {
  return combineReducers({
    getContent: postReducer,
    trendContent: trendReducer,
    trendSingle: trendSingle,
  });
}
