import React, { Component } from "react";
import Header from "../Header";
import Marquee from "react-double-marquee";
import Footer from "../footer";
import Footercontain from "../footercontain";
import { Link, Redirect } from "react-router-dom";
import Article from "./article";
import Textscroll from "../textscroll";
import Menu from "../menu";

class Post1 extends Component {
  state = {};

  render() {
    return (
      <React.Fragment>
        <Header />
        <Menu history={this.props.history} />
        <Textscroll />

        <section id="img" class="breadcrumbs-area">
          <div class="container">
            <div class="breadcrumbs-content">
              {/* <h1>Post </h1> */}
              <ul>
                <li>
                  <Link to="/">Home</Link>{" "}
                </li>
                {/* <li>Business</li> */}
              </ul>
            </div>
          </div>
        </section>
        <Article history={this.props.history} />
        <Footer />
      </React.Fragment>
    );
  }
}

export default Post1;
