import React, { Component } from "react";
import Footer from "./footer";
import Header from "./Header";
import Menu from "./menu";
import News from "./news";
import Slider from "./slider";
import Sports from "./sports";
import Videos from "./videos";
import Footercontain from "./footercontain";

class Home extends Component {
  state = {

  };

  async componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <React.Fragment>
        <Header />
        <Menu history={this.props.history} />
        <Slider />
        <News />
        <Sports />
        <Videos />
        <Footercontain />
        <Footer />
      </React.Fragment>
    );
  }
}

export default Home;
