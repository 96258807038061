import React, { Component } from "react";
import Aside from "./aside";
import {Link} from "react-router-dom";
import Asection from "./asection";

class Main extends Component {
  state = {};

  render() {
    return (
      <div class="ne-sidebar sidebar-break-md col-lg-4 col-md-12">
        <div class="sidebar-box">
          <div class="topic-border color-cod-gray mb-30">
            <div class="topic-box-lg color-cod-gray">Stay Connected</div>
          </div>
          <ul class="stay-connected overflow-hidden">
            <li class="facebook">
              <i class="fa fa-facebook" aria-hidden="true"></i>
              <div class="connection-quantity">50.2 k</div>
              <p>Fans</p>
            </li>
            <li class="twitter">
              <i class="fa fa-twitter" aria-hidden="true"></i>
              <div class="connection-quantity">10.3 k</div>
              <p>Followers</p>
            </li>
            <li class="linkedin">
              <i class="fa fa-linkedin" aria-hidden="true"></i>
              <div class="connection-quantity">25.4 k</div>
              <p>Fans</p>
            </li>
            <li class="rss">
              <i class="fa fa-rss" aria-hidden="true"></i>
              <div class="connection-quantity">20.8 k</div>
              <p>Subscriber</p>
            </li>
          </ul>
        </div>
        <Asection />

        <div class="sidebar-box">
          <div class="topic-border color-cod-gray mb-25">
            <div class="topic-box-lg color-cod-gray">Tags</div>
          </div>
          <ul class="sidebar-tags">
            <li>
              <Link to="/">Apple</Link>
            </li>
            <li>
              <Link to="/">Sports</Link>
            </li>
            <li>
              <Link to="/">Architecture</Link>
            </li>
            <li>
              <Link to="/">Gadgets</Link>
            </li>
            <li>
              <Link to="/">Software</Link>
            </li>
            <li>
              <Link to="/">Microsoft</Link>
            </li>
            <li>
              <Link to="/">Robotic</Link>
            </li>
            <li>
              <Link to="/">Technology</Link>
            </li>
            <li>
              <Link to="/">Others</Link>
            </li>
          </ul>
        </div>

        <Aside />
      </div>
    );
  }
}

export default Main;
