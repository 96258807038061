import React, { Component } from "react";
import blog1 from "../../assets/img/blog1.jpg";
import blog2 from "../../assets/img/blog2.jpg";
import {Link } from "react-router-dom"; 

class LiveComments extends Component {
  state = {};
  render() {
    return (
      <div class="comments-area">
        {/* <h2 class="title-semibold-dark size-xl border-bottom mb-40 pb-20">
          03 Comments
        </h2>
        <ul>
          <li>
            <div class="media media-none-xs">
              <img
                src={blog1}
                class="img-fluid rounded-circle"
                alt="comments"
              />
              <div class="text-align comments-content media-margin30">
                <h3 class="title-semibold-dark">
                  <a href="#">
                    Nitiya ,<span> August 29, 2017</span>
                  </a>
                </h3>
                <p>
                  Borem Ipsum is simply dummy text of the printing and
                  typesetting industry Lorem Ipsum has been the industry's
                  standard dummy text.
                </p>
              </div>
            </div>
          </li>
          <li>
            <div class="media media-none-xs">
              <img
                src={blog2}
                class="img-fluid rounded-circle"
                alt="comments"
              />
              <div class="text-align comments-content media-margin30">
                <h3 class="title-semibold-dark">
                  <Link to="">
                    Fahim ,<span> August 29, 2017</span>
                  </Link>
                </h3>
                <p>
                  Borem Ipsum is simply dummy text of the printing and
                  typesetting industry Lorem Ipsum has been the industry's
                  standard dummy text.
                </p>
              </div>
            </div>
          </li>
        </ul> */}
      </div>
    );
  }
}

export default LiveComments;
