import React, { Component } from "react";
import Marquee from "react-double-marquee";

class Textscroll extends Component {
  state = {};
  render() {
    return (
      <section className="bg-accent add-top-margin mt-0">
        <div className="container">
          <div className="row no-gutters d-flex align-items-center">
            <div className="col-lg-2 col-md-3 col-sm-4 col-4">
              <div className="topic-box mt-10 mb-10">Top Stories</div>
            </div>
            <div className="col-lg-10 col-md-9 col-sm-8 col-8">
              <div className="feeding-text-dark">
                <div className="text-scroll">
                  <Marquee>
                    McDonell Kanye West highlights difficulties for celebrities
                    Complimentary decor and design advice with Summit Park
                    Homes. Magnificent Image Of The New Hoover Dam Bridge Taking
                    Shape. If Obama Had Governed Like This in 2017 He'd Be the
                    Transformational.
                  </Marquee>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Textscroll;
