import React, { Component } from "react";
import auth from "../services/authServices";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

class Logout extends Component {
  componentDidMount() {
    auth.logout();

    toast.success("Logout Succcessfully", {
      position: toast.POSITION.TOP_RIGHT,
    });

    setTimeout(() => {
      window.location = "/";
    }, 3000);
  }

  render() {
    return null;
  }
}

export default Logout;
