import React, { Component } from "react";
import { Route, Router, Switch ,Redirect} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "./App.css";

import Home from "../src/components/home";
import Notfound from "./components/Notfound/index";
import search from "./components/searchpage/index";
import sports1 from "./components/sports/index";
import Posts from "./components/posts/post1/index";
import Politics from "./components/category/politics/index";
import Business from "./components/category/business/index";
import Sports from "./components/category/sports/index";
import Fashion from "./components/category/fashion/index";
import Travel from "./components/category/travel/index";
import Logout from "./components/logout";


import Trending from "./components/admin/trendingNews";
import Allposts from "./components/admin/allposts";
import AddNews from "./components/admin/addnewpost";
import Videos from "./components/admin/videos";
import Gallery from "./components/admin/gallery";


class App extends Component {
  state = {

  };

  // componentDidMount() {
  //   const user= auth.getCurrentUser();
  //   this.setState({user});
  
  // }


  

  render() {
  
    // const {user} = this.state;
    
    return (
      <React.Fragment>
        <ToastContainer />
        <main>

        
        <Switch>
          <Route exact path="/" component={Home} />

          <Route exact path="/notfound" component={Notfound}  history={this.props.history}/>
          <Route exact path="/search" component={search}  history={this.props.history}/>
          <Route path="/sports1" component={sports1}  history={this.props.history} />
          <Route exact path="/posts" component={Posts} />
          <Route exact path="/politics" component={Politics} />
          <Route exact path="/business" component={Business} />
          <Route exact path="/sports" component={Sports} />
          <Route exact path="/fashion" component={Fashion} />
          <Route exact path="/travel" component={Travel} />
          <Route exact path="/Logout" component={Logout} />
      
         

          
          {/* <Route exact path="/admin" 
          render={props =>{
            if(!user) return <Redirect to="/" />;
            return <Trending {...props} />;
          }}
         /> */}

        
          <Route exact path="/admin"  component={Trending} />
          <Route exact path="/allposts" component={Allposts} />
          <Route exact path="/editingpage" component={AddNews} history={this.props.history}/>
          <Redirect to="/notfound" component={Notfound}  history={this.props.history}  />
          <Route exact path="/videos" component={Videos} />
          <Route exact path="/gallery" component={Gallery} />

        </Switch>
        </main>
      </React.Fragment>
    );
  }
}

export default App;
