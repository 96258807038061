import { UPDATE_TRENDCONTENT } from "./../action/trendAction";
import { trendContent } from "./../../services/trendingService";

const initialState = trendContent();
const allContent = (state = initialState, { type, payload }) => {
  switch (type) {
    case UPDATE_TRENDCONTENT:
      return payload;
    default:
      return state;
  }
};
export default allContent;
