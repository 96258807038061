import React, { Component } from 'react';
import { Link, Redirect } from "react-router-dom";


class Article extends Component {
    state = {
       
        
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        }

    render() {
        
       
        
        
        return ( 
            <section class="bg-body section-space-less30">
                
                <div class="container">
                <div class="row">

                    <div class="col-md-12">
                    <h1 className="text-center text-danger">Sorry, there are no results for{}</h1>
                        <h2 className="text-center">Suggestions</h2>
                     

                        <ul class="text-center h4 mt-5">
                            <li class="mb-3">Make sure that all words are spelled correctly.</li>
                            <li class="mb-3">Try different keywords.</li>
                            <li class="mb-3">Try more general keywords.</li>
                        </ul>
                    </div>
                </div>


                </div>
                   
              
            </section>
         );
    }
}
 

export default Article;