import store from "../store/index";
import { getContent } from "./../../services/postServices";
export var UPDATE_GETCONTENT = "UPDATE_GETCONTENT";

function update_getContent() {
  setTimeout(async () => {
    var totalcontent = await getContent();
    console.log(totalcontent, "erferf");
    store.dispatch({ type: UPDATE_GETCONTENT, payload: totalcontent });
  }, 100);
}

export default update_getContent;
