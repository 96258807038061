import React, { Component } from "react";
import news117 from "../../assets/img/news/news117.jpg";
import news118 from "../../assets/img/news/news118.jpg";
import news119 from "../../assets/img/news/news119.jpg";
import news120 from "../../assets/img/news/news120.jpg";
import news121 from "../../assets/img/news/news121.jpg";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

class Aside extends Component {
  state = {
    content: [],
  };

  async componentDidMount() {
    const a = await this.props.getContent;
    this.setState({ content: a.data });
    window.scrollTo(0, 0);
  }

  render() {
    const ct = this.state.content.filter((content) => {
      return content.category.includes("Sports");
    });

    return (
      <div class="sidebar-box">
        <div class="topic-border color-cod-gray mb-30">
          <div class="topic-box-lg color-cod-gray">Most Reviews</div>
        </div>

        {ct.slice(1,5).map((p) => {
          return (
            <div class="position-relative mb30-list bg-body">
              <div class="topic-box-top-xs">
                <div class="topic-box-sm color-cod-gray mb-20">
                  {p.category}
                </div>
              </div>
              <div class="media">
                <div class="img-opacity-hover media-sm" to="">
                  <img src={p.imageURL} alt="news" class="recent-side " />
                </div>
                <div class="media-cat">
                  <div class="post-date-dark">
                    <ul>
                      <li>
                        <span>
                          <i class="fa fa-calendar" aria-hidden="true"></i>
                        </span>
                        {p.date}
                      </li>
                    </ul>
                  </div>
                  <h3 class="title-medium-dark mb-none">
                    <Link to={{ pathname: "/sports1", state: { p } }}>
                      {p.title}
                    </Link>
                  </h3>
                </div>
              </div>
            </div>
          );
        })}

        {/* <div class="position-relative mb30-list bg-body">
                    <div class="topic-box-top-xs">
                        <div class="topic-box-sm color-cod-gray mb-20">Gadgets</div>
                    </div>
                    <div class="media">
                        <a class="img-opacity-hover" href="single-news-2.html">
                            <img src={news118} alt="news" class="img-fluid"/>
                        </a>
                        <div class="text-align">
                            <div class="post-date-dark">
                                <ul>
                                    <li>
                                        <span>
                                            <i class="fa fa-calendar" aria-hidden="true"></i>
                                        </span>June 06, 2017</li>
                                </ul>
                            </div>
                            <h3 class="title-medium-dark mb-none">
                                <a href="single-news-3.html">Can Be Monit roade year with Program.</a>
                            </h3>
                        </div>
                    </div>
                </div> */}
        {/* 
                <div class="position-relative mb30-list bg-body">
                    <div class="topic-box-top-xs">
                        <div class="topic-box-sm color-cod-gray mb-20">Software</div>
                    </div>
                    <div class="media">
                        <a class="img-opacity-hover" href="single-news-3.html">
                            <img src={news119} alt="news" class="img-fluid"/>
                        </a>
                        <div class="text-align">
                            <div class="post-date-dark">
                                <ul>
                                    <li>
                                        <span>
                                            <i class="fa fa-calendar" aria-hidden="true"></i>
                                        </span>August 22, 2017</li>
                                </ul>
                            </div>
                            <h3 class="title-medium-dark mb-none">
                                <a href="">Can Be Monit roade year with Program.</a>
                            </h3>
                        </div>
                    </div>
                </div> */}

        {/* <div class="position-relative mb30-list bg-body">
                    <div class="topic-box-top-xs">
                        <div class="topic-box-sm color-cod-gray mb-20">Tech</div>
                    </div>
                    <div class="media">
                        <a class="img-opacity-hover" href="">
                            <img src={news120} alt="news" class="img-fluid"/>
                        </a>
                        <div class="text-align">
                            <div class="post-date-dark">
                                <ul>
                                    <li>
                                        <span>
                                            <i class="fa fa-calendar" aria-hidden="true"></i>
                                        </span>February 10, 2017</li>
                                </ul>
                            </div>
                            <h3 class="title-medium-dark mb-none">
                                <a href="single-news-2.html">Can Be Monit roade year with Program.</a>
                            </h3>
                        </div>
                    </div>
                </div> */}

        {/*                 
                <div class="position-relative mb30-list bg-body">
                    <div class="topic-box-top-xs">
                        <div class="topic-box-sm color-cod-gray mb-20">Ipad</div>
                    </div>
                    <div class="media">
                        <a class="img-opacity-hover" href="">
                            <img src={news121} alt="news" class="img-fluid"/>
                        </a>
                        <div class="text-align">
                            <div class="post-date-dark">
                                <ul>
                                    <li>
                                        <span>
                                            <i class="fa fa-calendar" aria-hidden="true"></i>
                                        </span>February 10, 2017</li>
                                </ul>
                            </div>
                            <h3 class="title-medium-dark mb-none">
                                <a href="single-news-2.html">Can Be Monit roade year with Program.</a>
                            </h3>
                        </div>
                    </div>
                </div> */}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    getContent: state.getContent,
  };
};
export default connect(mapStateToProps)(Aside);
