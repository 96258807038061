import React, { Component } from "react";

class Comment extends Component {
  state = {};
  
  render() {
    return (
      <div class="leave-comments">
        {/* <h2 class="title-semibold-dark size-xl mb-40">Leave Comments</h2>
        <form id="leave-comments">
          <div class="row">
            <div class="col-md-4 col-sm-12">
              <div class="form-group">
                <input placeholder="Name*" class="form-control" type="text" />
                <div class="help-block with-errors"></div>
              </div>
            </div>
            <div class="col-md-4 col-sm-12">
              <div class="form-group">
                <input placeholder="Email*" class="form-control" type="email" />
                <div class="help-block with-errors"></div>
              </div>
            </div>
            <div class="col-md-4 col-sm-12">
              <div class="form-group">
                <input
                  placeholder="Web Address"
                  class="form-control"
                  type="text"
                />
                <div class="help-block with-errors"></div>
              </div>
            </div>
            <div class="col-12">
              <div class="form-group">
                <textarea
                  placeholder="Message*"
                  class="textarea form-control"
                  id="form-message"
                  rows="8"
                  cols="20"
                ></textarea>
                <div class="help-block with-errors"></div>
              </div>
            </div>
            <div class="col-12">
              <div class="form-group mb-none">
                <button type="submit" class="btn-ftg-ptp-45">
                  Post Comment
                </button>
              </div>
            </div>
          </div>
        </form> */}
      </div>
    );
  }
}

export default Comment;
