import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { Link } from "react-router-dom";
import gif from "../assets/img/loader.gif";
import news31 from "../assets/img/news/news31.jpg";
import news32 from "../assets/img/news/news32.jpg";
import news33 from "../assets/img/news/news33.jpg";
import news34 from "../assets/img/news/news34.jpg";
import news35 from "../assets/img/news/news35.jpg";
import news36 from "../assets/img/news/news36.jpg";
import news37 from "../assets/img/news/news37.jpg";
import news51 from "../assets/img/news/news51.jpg";
import news50 from "../assets/img/news/news50.jpg";
import news52 from "../assets/img/news/news52.jpg";
import { connect } from "react-redux";

class Sports extends Component {
  state = {
    content: [],
  };

  async componentDidMount() {
    const a = await this.props.trendContent;
    this.setState({ content: a.data });
  }

  render() {
    const ct = this.state.content[0];
    const bt = this.state.content[1];

    const pt = this.state.content[2];

    const tt = this.state.content[3];
    return (
      <div className="container">
        <div className="ne-isotope">
          <div className="row">
            <div className="col-12">
              <Tabs>
                <div className="topic-border color-azure-radiance mb-30">
                  <div className="topic-box-lg color-azure-radiance">NEWS</div>
                  <div className="isotope-classNamees-tab isotop-btn">
                    <TabList>
                      <Tab>Sports</Tab>
                      <Tab>Business</Tab>
                      <Tab>Travel</Tab>
                      <Tab>Politics</Tab>
                    </TabList>
                  </div>
                </div>

                <div>
                  <TabPanel>
                    <div class="row ">
                      <div class="col-xl-4 col-lg-7 col-md-6 col-sm-12">
                        <div class="img-overlay-70 img-scale-animate mb-30">
                          {ct && ct.length > 0 ? (
                            <img
                              src={ct[5].imageURL}
                              alt="news"
                              class="news_img width-100"
                            />
                          ) : (
                            gif
                          )}
                          <div class="topic-box-top-lg">
                            <div class="topic-box-sm color-cod-gray mb-20">
                              {ct && ct.length > 0 ? ct[5].category : null}
                            </div>
                          </div>
                          <div class="mask-content-lg">
                            <div class="post-date-light">
                              <ul>
                                <li>
                                  <span>by</span>
                                  {/* <Link to="/"> */}
                                    Adams
                                    {/* </Link> */}
                                </li>
                                <li>
                                  <span>
                                    <i
                                      class="fa fa-calendar"
                                      aria-hidden="true"
                                    ></i>
                                  </span>
                                  {ct && ct.length > 0 ? ct[5].date : null}
                                </li>
                              </ul>
                            </div>
                            <h2 class="title-medium-light size-lg">
                              <Link
                                to={{
                                  pathname: "/sports1",
                                  state: {
                                    p: ct && ct.length > 0 ? ct[5] : null,
                                  },
                                }}
                              >
                                {ct && ct.length > 0 ? ct[5].title : null}
                              </Link>
                            </h2>
                          </div>
                        </div>
                      </div>

                      <div class="col-xl-8 col-lg-5 col-md-6 col-sm-12">
                        <div class="row keep-items-4-md">
                          {ct &&
                            ct.length > 0 &&
                            ct.slice(6, 12).map((p, index) => {
                              return (
                                <div class="col-xl-4 col-lg-6 col-md-6 col-sm-4 col-6">
                                  <div class="mb-25 position-relative">
                                    <div class="img-opacity-hover" to="">
                                      <img
                                        src={p.imageURL}
                                        alt="news"
                                        class="mb-15 sports-sm"
                                      />
                                    </div>
                                    <div class="topic-box-top-xs">
                                      <div class="topic-box-sm color-cod-gray mb-20">
                                        {p.category}
                                      </div>
                                    </div>
                                    <div class="post-date-dark">
                                      <ul>
                                        <li>
                                          <span>
                                            <i
                                              class="fa fa-calendar"
                                              aria-hidden="true"
                                            ></i>
                                          </span>
                                          {p.date}
                                        </li>
                                      </ul>
                                    </div>
                                    <h3 class="title-medium-dark size-md">
                                      <Link
                                        to={{
                                          pathname: "/sports1",
                                          state: { p },
                                        }}
                                      >
                                        {p.title}
                                      </Link>
                                    </h3>
                                  </div>
                                </div>
                              );
                            })}
                        </div>
                      </div>
                    </div>
                  </TabPanel>

                  <TabPanel>
                    <div class="row ">
                      <div class="col-xl-4 col-lg-7 col-md-6 col-sm-12">
                        <div class="img-overlay-70 img-scale-animate mb-30">
                          {bt && bt.length > 0 ? (
                            <img
                              src={bt[6].imageURL}
                              alt="news"
                              class="news_img width-100"
                            />
                          ) : (
                            gif
                          )}
                          <div class="topic-box-top-lg">
                            <div class="topic-box-sm color-cod-gray mb-20">
                              {bt && bt.length > 0 ? bt[6].category : null}
                            </div>
                          </div>
                          <div class="mask-content-lg">
                            <div class="post-date-light">
                              <ul>
                                <li>
                                  <span>by</span>
                                  {/* <Link to="/"> */}
                                    Adams
                                    {/* </Link> */}
                                </li>
                                <li>
                                  <span>
                                    <i
                                      class="fa fa-calendar"
                                      aria-hidden="true"
                                    ></i>
                                  </span>
                                  {bt && bt.length > 0 ? bt[6].date : null}
                                </li>
                              </ul>
                            </div>
                            <h2 class="title-medium-light size-lg">
                              <Link
                                to={{
                                  pathname: "/sports1",
                                  state: {
                                    p: bt && bt.length > 0 ? bt[6] : null,
                                  },
                                }}
                              >
                                {bt && bt.length > 0 ? bt[6].title : null}
                              </Link>
                            </h2>
                          </div>
                        </div>
                      </div>

                      <div class="col-xl-8 col-lg-5 col-md-6 col-sm-12">
                        <div class="row keep-items-4-md">
                          {bt &&
                            bt.length > 0 &&
                            bt.slice(7, 13).map((p, index) => {
                              return (
                                <div class="col-xl-4 col-lg-6 col-md-6 col-sm-4 col-6">
                                  <div class="mb-25 position-relative">
                                    <div class="img-opacity-hover" to="">
                                      <img
                                        src={p.imageURL}
                                        alt="news"
                                        className="mb-15 sports-sm"
                                      />
                                    </div>
                                    <div class="topic-box-top-xs">
                                      <div class="topic-box-sm color-cod-gray mb-20">
                                        {p.category}
                                      </div>
                                    </div>
                                    <div class="post-date-dark">
                                      <ul>
                                        <li>
                                          <span>
                                            <i
                                              class="fa fa-calendar"
                                              aria-hidden="true"
                                            ></i>
                                          </span>
                                          {p.date}
                                        </li>
                                      </ul>
                                    </div>
                                    <h3 class="title-medium-dark size-md">
                                      <Link
                                        to={{
                                          pathname: "/sports1",
                                          state: { p },
                                        }}
                                      >
                                        {p.title}
                                      </Link>
                                    </h3>
                                  </div>
                                </div>
                              );
                            })}
                        </div>
                      </div>
                    </div>
                  </TabPanel>

                  <TabPanel>
                    <div class="row ">
                      <div class="col-xl-4 col-lg-7 col-md-6 col-sm-12">
                        <div class="img-overlay-70 img-scale-animate mb-30">
                          {tt && tt.length > 0 ? (
                            <img
                              src={tt[2].imageURL}
                              alt="news"
                              class="news_img width-100"
                            />
                          ) : (
                            gif
                          )}
                          <div class="topic-box-top-lg">
                            <div class="topic-box-sm color-cod-gray mb-20">
                              {tt && tt.length > 0 ? tt[2].category : null}
                            </div>
                          </div>
                          <div class="mask-content-lg">
                            <div class="post-date-light">
                              <ul>
                                <li>
                                  <span>by</span>
                                  {/* <Link to="/"> */}
                                    Adams
                                    {/* </Link> */}
                                </li>
                                <li>
                                  <span>
                                    <i
                                      class="fa fa-calendar"
                                      aria-hidden="true"
                                    ></i>
                                  </span>
                                  {tt && tt.length > 0 ? tt[2].date : null}
                                </li>
                              </ul>
                            </div>
                            <h2 class="title-medium-light size-lg">
                              <Link
                                to={{
                                  pathname: "/sports1",
                                  state: {
                                    p: tt && tt.length > 0 ? tt[2] : null,
                                  },
                                }}
                              >
                                {tt && tt.length > 0 ? tt[2].title : null}
                              </Link>
                            </h2>
                          </div>
                        </div>
                      </div>

                      <div class="col-xl-8 col-lg-5 col-md-6 col-sm-12">
                        <div class="row keep-items-4-md">
                          {tt &&
                            tt.length > 0 &&
                            tt.slice(3, 9).map((p, index) => {
                              return (
                                <div class="col-xl-4 col-lg-6 col-md-6 col-sm-4 col-6">
                                  <div class="mb-25 position-relative">
                                    <div class="img-opacity-hover" to="">
                                      <img
                                        src={p.imageURL}
                                        alt="news"
                                        className="mb-15 sports-sm"
                                      />
                                    </div>
                                    <div class="topic-box-top-xs">
                                      <div class="topic-box-sm color-cod-gray mb-20">
                                        {p.category}
                                      </div>
                                    </div>
                                    <div class="post-date-dark">
                                      <ul>
                                        <li>
                                          <span>
                                            <i
                                              class="fa fa-calendar"
                                              aria-hidden="true"
                                            ></i>
                                          </span>
                                          {p.date}
                                        </li>
                                      </ul>
                                    </div>
                                    <h3 class="title-medium-dark size-md">
                                      <Link
                                        to={{
                                          pathname: "/sports1",
                                          state: { p },
                                        }}
                                      >
                                        {p.title}
                                      </Link>
                                    </h3>
                                  </div>
                                </div>
                              );
                            })}
                        </div>
                      </div>
                    </div>
                  </TabPanel>

                  <TabPanel>
                    <div class="row ">
                      <div class="col-xl-4 col-lg-7 col-md-6 col-sm-12">
                        <div class="img-overlay-70 img-scale-animate mb-30">
                          {pt && pt.length > 0 ? (
                            <img
                              src={pt[5].imageURL}
                              alt="news"
                              class="news_img width-100"
                            />
                          ) : null}
                          <div class="topic-box-top-lg">
                            <div class="topic-box-sm color-cod-gray mb-20">
                              {pt && pt.length > 0 ? pt[5].category : null}
                            </div>
                          </div>
                          <div class="mask-content-lg">
                            <div class="post-date-light">
                              <ul>
                                <li>
                                  <span>by</span>
                                  {/* <Link to="/"> */}
                                    Adams
                                    {/* </Link> */}
                                </li>
                                <li>
                                  <span>
                                    <i
                                      class="fa fa-calendar"
                                      aria-hidden="true"
                                    ></i>
                                  </span>
                                  {pt && pt.length > 0 ? pt[5].date : null}
                                </li>
                              </ul>
                            </div>
                            <h2 class="title-medium-light size-lg">
                              <Link
                                to={{
                                  pathname: "/sports1",
                                  state: {
                                    p: pt && pt.length > 0 ? pt[5] : null,
                                  },
                                }}
                              >
                                {pt && pt.length > 0 ? pt[5].title : null}
                              </Link>
                            </h2>
                          </div>
                        </div>
                      </div>

                      <div class="col-xl-8 col-lg-5 col-md-6 col-sm-12">
                        <div class="row keep-items-4-md">
                          {pt &&
                            pt.length > 0 &&
                            pt.slice(6, 11).map((p, index) => {
                              return (
                                <div class="col-xl-4 col-lg-6 col-md-6 col-sm-4 col-6">
                                  <div class="mb-25 position-relative">
                                    <div class="img-opacity-hover" to="">
                                      <img
                                        src={p.imageURL}
                                        alt="news"
                                        className="mb-15 sports-sm"
                                      />
                                    </div>
                                    <div class="topic-box-top-xs">
                                      <div class="topic-box-sm color-cod-gray mb-20">
                                        {p.category}
                                      </div>
                                    </div>
                                    <div class="post-date-dark">
                                      <ul>
                                        <li>
                                          <span>
                                            <i
                                              class="fa fa-calendar"
                                              aria-hidden="true"
                                            ></i>
                                          </span>
                                          {p.date}
                                        </li>
                                      </ul>
                                    </div>
                                    <h3 class="title-medium-dark size-md">
                                      <Link
                                        to={{
                                          pathname: "/sports1",
                                          state: { p },
                                        }}
                                      >
                                        {p.title}
                                      </Link>
                                    </h3>
                                  </div>
                                </div>
                              );
                            })}
                        </div>
                      </div>
                    </div>
                  </TabPanel>
                </div>
              </Tabs>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    trendContent: state.trendContent,
  };
};
export default connect(mapStateToProps)(Sports);
