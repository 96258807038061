import React, { Component } from "react";
import logo from "../assets/img/logo.png";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

class Footer extends Component {
  state = {
    trend: [],
  };

  async componentDidMount() {


    const a = await this.props.trendSingle;
    this.setState({ trend: a.data });
  }

  render() {
    return (
      <footer>
        <div className="footer-area-bottom">
          <div className="container">
            <div className="row">
              <div className="col-12 text-center">
                <Link to="/" className="footer-logo img-fluid">
                  <img src={logo} alt="logo" className="img-fluid" />
                </Link>
                <ul className="social-default item-inline">
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                </ul>
                <ul className="footer-social">
                  <li>
                    <a
                      href="https://www.facebook.com/"
                      target="_blank"
                      className="facebook"
                      title="facebook"
                    >
                      <i className="fa fa-facebook" aria-hidden="true"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://twitter.com/LOGIN"
                      target="_blank"
                      className="twitter"
                      title="twitter"
                    >
                      <i className="fa fa-twitter" aria-hidden="true"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://accounts.google.com/login"
                      target="_blank"
                      className="google"
                    >
                      <i className="fa fa-google-plus" aria-hidden="true"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.linkedin.com/login"
                      target="_blank"
                      className="linkedin"
                    >
                      <i className="fa fa-linkedin" aria-hidden="true"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://in.pinterest.com/login/"
                      className="pinterest"
                      target="_blank"
                    >
                      <i className="fa fa-pinterest" aria-hidden="true"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://feeder.co/login"
                      target="_blank"
                      className="rss"
                    >
                      <i className="fa fa-rss" aria-hidden="true"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://vimeo.com/log_in"
                      target="_blank"
                      title="vimeo"
                    >
                      <i className="fa fa-vimeo" aria-hidden="true"></i>
                    </a>
                  </li>
                </ul>
                <p>
                  © 2020 CG news Designed by Codegene Software Solutions. All
                  Rights Reserved
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    
    trendSingle: state.trendSingle,
  };
};
export default connect(mapStateToProps)(Footer);
