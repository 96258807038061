import http from "./httpService";
import { apiUrl } from "../config.json";

const apiEndpoint = apiUrl + "/category";

const apiEndpoint1 = apiUrl + "/category/single";

export function trendContent() {
  return http.get(apiEndpoint);
}

export function trendSingle() {
  return http.get(apiEndpoint1);
}

export default {
  trendContent,
  trendSingle,
};
